<template>
  <section fluid>
    <div class="text-h6">
      <p class="secondary--text mb-n1">
        Perfil del proveedor - Agregar documentación
      </p>
      <h6 class="grayMinsal--text" v-if="this.provider != null">
        Proveedor {{ getTipoContribuidor }} - {{ provider.tipo_contribuyente }}
      </h6>
    </div>
    <v-expansion-panels focusable class="mt-12">
      <v-expansion-panel
        v-for="(item, index) in requiredDocs"
        :key="index"
        class="panel mt-2"
      >
        <v-expansion-panel-header
          >{{ item.tipo_documento }}
          <span v-if="item.requerido" class="ml-2 error--text"
            >Requerido</span
          ></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <formPanel
            ref="refFP"
            class="mt-6"
            :dateinputL="obtenerTextoFechaDocumento(item.fecha_label, item.fecha_expiracion)"
            :fileinputL="'Documento *'"
            :textinputL="`${item.tipo_documento} *`"
            :libroRegistroinputL="item.libro_registro === true ? 'Libro de registro *' : null"
            :idTipoDocumento="item.id_tipo_documento"
            :mask="item.formato"
            :format="item.formato"
            :numeroDocumentoLabel="item.numero_documento_label"
          ></formPanel>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row>
      <v-col class="my-5" cols="12">
        <v-btn outlined @click="$router.push({ name: 'dashboard' })">
          Regresar
        </v-btn>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { obtenerTextoFechaDocumento } from '@/utils/proveedores';
import formPanel from "./components/FormPanelComponent.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "agregarDocumentacionView",
  components: { formPanel },
  data: () => ({
    requiredDocs: [],
  }),
  computed: {
    ...mapState("proveedores", ["provider"]),
    getTipoContribuidor() {
      if (
        this.provider?.identificacion_tributaria?.nombre?.includes(
          "Salvadoreño"
        )
      )
        return "nacional";
      else return "extranjero";
    },
  },
  methods: {
    ...mapActions("proveedores", ["getProvider"]),
    obtenerTextoFechaDocumento,
    goBack() {
      this.$router.push({ name: "perfil_proveedor" });
    },
    async getRequiredDocs() {
      const response =
        await this.services.Proveedores.getProviderDocumentRequired();
      if (response.status === 200) {
        this.requiredDocs = response.data;
      }
    },
    verifyCompletedDocs() {
      const documents = this.provider?.documentos;

      if (documents)
        documents.forEach((elemento1) => {
          this.requiredDocs.forEach((elemento2) => {
            if (elemento1?.id_tipo_documento === elemento2?.id_tipo_documento) {
              if (elemento2?.requerido) {
                if (
                  elemento1?.numero_documento != null ||
                  elemento1?.numero_documento != ""
                ) {
                  elemento2.requerido = false;
                }
              }
            }
          });
        });
    },
  },
  watch: {
    provider() {
      this.verifyCompletedDocs();
    },
    requiredDocs() {
      this.verifyCompletedDocs();
    },
  },
  created() {
    this.getRequiredDocs();
    if (Object.keys(this.provider).length == 0) {
      this.getProvider();
    }
  },
};
</script>

<style lang="scss" scoped>
:deep(.v-expansion-panel--active > .v-expansion-panel-header) {
  background: #111c4e !important;
  color: white !important;
}
:deep(.v-expansion-panel--active .v-expansion-panel-header__icon i) {
  color: white !important;
}
</style>
