import { lowerCase, capitalize } from "lodash";

export const obtenerTextoFechaDocumento = (label, fechaExpiracion, suffix = '*') => {
    if (!fechaExpiracion || fechaExpiracion === false) return null;

    return `${capitalize(label ?? 'Fecha')} ${suffix}`;
}

export const obtenerErrorDocumentoRequerido = (label) => {
    return `El campo ${lowerCase(label ?? 'fecha')} es obligatorio`;
}
